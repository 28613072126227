// Import only the Bootstrap components we need
import { Popover } from "bootstrap";

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popover) => {
  new Popover(popover);
});

// function showImg2(){
//   document.getElementsByClassName('imgServices')
// }

document.addEventListener("DOMContentLoaded", (event) => {
  // Seleccionamos todos los botones del acordeón
  const botonesAcordeon = document.querySelectorAll(".accordion-button");

  botonesAcordeon.forEach((btn) => {
    btn.addEventListener("click", () => {
      const imagenDinamica = document.getElementById("imagenDinamica");

      switch (btn.innerText) {
        case "Título 1":
          imagenDinamica.src = "./images/";
          break;
        // ... Otros casos ...
        default:
          imagenDinamica.src = "ruta/default.jpg";
          break;
      }
    });
  });
});
